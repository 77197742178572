<template>
  <div>
    <h1 class=" text-text2 text-center text-4xl mt-6">No reports available at this time.</h1>
  </div>
</template>
<!-- <template>
  <div class="my-app">
    <D3BarChart :config="chart_config" :datum="chart_data"></D3BarChart>
  </div>
</template> -->
<script>
// import { D3BarChart } from 'vue-d3-charts';
export default {
  components: {
    // D3BarChart,
  },
  data() {
    return {
      chart_data: [
        {vue: 20, d3: 62, category: 'qasd'},
        {vue: 28, d3: 47, category: 'ipsum'},
        {vue: 22, d3: 36, category: 'asda'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'asdas'},
        {vue: 22, d3: 36, category: 'dolsdaor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 645, d3: 36, category: 'dodor'},
        {vue: 56, d3: 36, category: 'dolor'},
        {vue: 64, d3: 36, category: 'dolador'},
        {vue: 32, d3: 36, category: 'adalor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 35, d3: 64, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dodadlor'},
        {vue: 35, d3: 36, category: 'dolador'},
        {vue: 246, d3: 23, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'doadlor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 26, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'doadlor'},
        {vue: 35, d3: 36, category: 'dolerggror'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'doat'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dodlor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 64, d3: 36, category: 'dolodr'},
        {vue: 426, d3: 36, category: 'doadlor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dolsaor'},
        {vue: 35, d3: 36, category: 'dolfweor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 246, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dolerteor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dolerteror'},
        {vue: 213, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dortlor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'doleror'},
        {vue: 35, d3: 36, category: 'dolortretr'},
        {vue: 35, d3: 36, category: 'dolotertr'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 123, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dolertor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'doloer'},
        {vue: 552, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dolotr'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 854, d3: 36, category: 'dolerteor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dolorrtor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 548, d3: 36, category: 'doloertr'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'doloer'},
        {vue: 35, d3: 36, category: 'dolotrr'},
        {vue: 458, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 35, d3: 548, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 548, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 35, d3: 21, category: 'dolor'},
        {vue: 568, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dolteror'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dolor'},
        {vue: 35, d3: 36, category: 'dolor'},
      ],
      chart_config: {
        key: 'category',
        values: ['vue', 'd3'],
        orientation: 'horizontal',
        color: {
          keys: {
            vue: '#41b882',
            d3: '#b35899', 
          },
        },
      },
    }
  },
  mounted() {
    document.title = 'Dealer Network ◾ Transmitters'
  },
};
</script>